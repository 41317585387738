import React, { memo } from "react";
import {
  NotificationIcon,
  WatchingNeighbourIcon,
  WatchSoldIcon,
} from "../../../SvgIcons/Icons";

const GetAlertCard = ({
  handleWatchNeighborHoodClick,
  handleNotifyWhenSoldClick,
  isNotified,
  isWatchingNeighbour,
}) => {
  return (
    <div
      className="Prop_details_section_box Propert_detailRepresentatorCard d-flex"
      style={{ overflow: "hidden", background: "#FAFAFA" }}
    >
      <div
        className="propertyDetail_homeValue_icon"
        style={{ width: "fit-content", height: "fit-content" }}
      >
        <NotificationIcon />
      </div>
      <div className="ps-3">
        <h6>Get Alert</h6>
        <p>
          Receive updates for Condo Apt homes in Waterfront Communities C8 -
          Toronto
        </p>
        <button className="mt-3 propertyDetails_moreInfo prop_notifyBtn Prop_getAlertCardBtn Prop_getAlertCardBtn_1">
          Watch Neighborhood
          <span
            className={`DetailsIcon_greyBg ${
              isWatchingNeighbour ? "DetailsIcon_LightBg" : ""
            }`}
            onClick={handleWatchNeighborHoodClick}
          >
            <WatchingNeighbourIcon
              color={isWatchingNeighbour ? "#007BFF" : "#282828"}
              size={"24"}
            />
          </span>
        </button>
        <button className="propertyDetails_moreInfo prop_notifyBtn pb-0 Prop_getAlertCardBtn Prop_getAlertCardBtn_2">
          Notify when Sold{" "}
          <span
            className={`DetailsIcon_greyBg ${
              isNotified ? "DetailsIcon_LightBg" : ""
            }`}
            onClick={handleNotifyWhenSoldClick}
          >
            <WatchSoldIcon
              color={isNotified ? "#007BFF" : "#282828"}
              size={"24"}
            />
          </span>
        </button>
      </div>
    </div>
  );
};

export default memo(GetAlertCard);
