import React, { memo } from "react";

const PropertyAgentCard = ({ handleInfoModalClick }) => {
  return (
    <div className="Prop_details_section_box Propert_detailRepresentatorCard">
      <div className="d-flex align-items-start">
        <div>
          <img
           src="https://config.assignmentsold.ca/Attachments/Newhomez-Agent-Profile.jpg"
            alt="Representator Profile"
            loading="lazy"
          />
        </div>
        <div className="ps-3">
          <h5 className="mt-0">Talha Chishti</h5>
          <p className="mb-0">Sales Representative</p>
          <p className="mb-0">AssignmentSold.ca Inc.</p>
          <p className="fw-bold mb-0 mt-1">(647) 649 0400</p>
        </div>
      </div>
      <button
        className="mt-3 propertyDetails_moreInfo PropDetails_gradientBtn"
        onClick={handleInfoModalClick}
      >
        Request for more info
      </button>
    </div>
  );
};

export default memo(PropertyAgentCard);
