import React, { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import { Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  BackArrowIcon,
  CalculatorIcon,
  HeartEmptyIcon,
  LocationHeaderIcon,
  PropertyCommuteIcon,
  ShareIcon,
  WatchingNeighbourIcon,
  WatchSoldIcon,
} from "../../../SvgIcons/Icons";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";

const PropertyHeader = ({
  cardIde,
  data,
  handleWatchNeighborHoodClick,
  handleNotifyWhenSoldClick,
  handleCommuteModalClick,
  handleHeartClick,
  isMobileView,
  isScrollingDown,
  isNotified,
  isWatchingNeighbour,
  setIsCalculate,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const linkSlug = data?.slug;

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${data?.latitude},${data?.longitude}`;
    window.open(url, "_blank");
  };

  const handleBackClick = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  //Share work
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };
  const [, setCopied] = useState(false);

  const handleCopyLink = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      handlePopClose();
    }, 1500);
  };

  const handleFacebookLinkShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${`https://assignmentsold.ca/assignment-sale/`}${linkSlug}`
    );
  };
  const handleLinkdinLinkShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };
  const handleWhatsAppLinkShare = () => {
    window.open(
      `https://wa.me/?text=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };
  const handletwitterLinkShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };

  return (
    <div
      className={`py-2 position-sticky ${
        isScrollingDown ? "Prop_secHeaderTop" : "Prop_header_static"
      }`}
      style={{
        borderBottom: "1px solid var(--Accent-Medium-Gray, #E2E2E2)",
        background: "#fff",
      }}
    >
      <div className="d-flex container align-items-center Propert_details_main_container Prop_header_container pt-0 justify-content-between">
        {!isMobileView && (
          <div className="Property_backSearch" onClick={handleBackClick}>
            <BackArrowIcon />
            Back to Search
          </div>
        )}
        <div className="d-flex align-items-center gap-2">
          {isMobileView && (
            <button className="PropertyHeader_Icon" onClick={handleBackClick}>
              <MdOutlineKeyboardArrowLeft size={"24"} />
            </button>
          )}

          <button className="PropertyHeader_Icon">
            <Link
              spy={true}
              activeClass="active"
              offset={-100}
              smooth={true}
              duration={500}
              to={"calculator"}
              onClick={() => setIsCalculate(true)}
            >
              <CalculatorIcon />
            </Link>
          </button>

          <button
            className={`PropertyHeader_Icon ${
              isNotified ? "DetailsIcon_LightBg" : ""
            }`}
            onClick={handleNotifyWhenSoldClick}
          >
            <WatchSoldIcon
              color={isNotified ? "#007BFF" : "#282828"}
              size={"24"}
            />
          </button>

          <button
            className={`PropertyHeader_Icon ${
              isWatchingNeighbour ? "DetailsIcon_LightBg" : ""
            }`}
            onClick={handleWatchNeighborHoodClick}
          >
            <WatchingNeighbourIcon
              color={isWatchingNeighbour ? "#007BFF" : "#282828"}
              size={"24"}
            />
          </button>

          {!isMobileView && (
            <button className="PropertyHeader_Icon" onClick={openGoogleMaps}>
              <LocationHeaderIcon />
            </button>
          )}

          <button
            className="PropertyHeader_Icon Hide_HeaderIcon"
            onClick={handleCommuteModalClick}
          >
            <PropertyCommuteIcon color={"#282828"} size={"24"} />
          </button>

          <button
            className="PropertyHeader_Icon"
            onClick={() => {
              handleHeartClick(data?.uId);
            }}
          >
            <HeartEmptyIcon
              color={"#282828"}
              fill={cardIde.includes(data?.uId) ? "#282828" : "none"}
            />
          </button>

          <div className="PropertyHeader_Icon">
            <IconButton
              aria-describedby="simple-popover"
              variant="contained"
              className="p-0"
              onClick={handlePopClick}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ShareIcon />
            </IconButton>
            <Popover
              id="simple-popover"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handlePopClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              className="Share_featuresPopover"
              sx={{
                "& .MuiPopover-paper": {
                  borderRadius: "52px",
                  boxShadow: "none",
                  top: "100px !important",
                  width: "156px",
                },
              }}
            >
              <Typography sx={{ p: 1 }}>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleCopyLink}
                  className="share__logos m-1"
                >
                  <img
                    src={require("../../../images/facebooklogo.png")}
                    className="share__logos"
                    alt="facebook logo"
                    onClick={handleFacebookLinkShare}
                    loading="lazy"
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleCopyLink}
                  className="share__logos"
                >
                  <img
                    src={require("../../../images/linkdinLogo.png")}
                    className="share__logos"
                    alt="Linkdin logo"
                    onClick={handleLinkdinLinkShare}
                    loading="lazy"
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={window.location.href}
                  onCopy={handleCopyLink}
                  className="share__logos mx-2"
                >
                  <img
                    src={require("../../../images/twitterlogo.png")}
                    className="share__logos"
                    alt="twitter logo"
                    onClick={handletwitterLinkShare}
                    loading="lazy"
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={`${window.location.href}/${linkSlug}`}
                  onCopy={handleCopyLink}
                >
                  <img
                    src={require("../../../images/whatsapplogo.png")}
                    className="share__logos"
                    alt="whatsapp logo"
                    onClick={handleWhatsAppLinkShare}
                    loading="lazy"
                  />
                  {/* </a> */}
                </CopyToClipboard>
              </Typography>
            </Popover>
          </div>

          <button
            className="PropertyHeader_Icon DetailsIcon_LightBg"
            onClick={handleBackClick}
          >
            <MdOutlineKeyboardArrowLeft size={"24"} color="#007BFF" />
          </button>

          <button
            className="PropertyHeader_Icon DetailsIcon_LightBg"
            onClick={handleBackClick}
          >
            <MdOutlineKeyboardArrowRight size={"24"} color="#007BFF" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(PropertyHeader);
