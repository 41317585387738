import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControl, FormLabel } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { Box, Modal } from "@mui/material";
import {
  CloseIcon,
  CompareFavouritesIcon,
  ExploreSoldListingIcon,
  NotificationIcon,
  PasswordShowEyeIcon,
  PasswordTypeEyeIcon,
} from "../../SvgIcons/Icons";
import { UserSignUpSchema } from "../SignUp/Subcomponents/UserSignUpSchema";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { addToken } from "../../ReduxStore/Slices/TokenTimeSlice";
import { addProfileData } from "../../ReduxStore/Slices/ProfileDataSlice";
import { toast } from "react-toastify";
import axios from "axios";
import baseURL from "../../config";
import { LoginSchema } from "../LoginPage/LoginSchema";
import { addItem } from "../../ReduxStore/Slices/LoginSlice";
import { NavLink } from "react-router-dom";

const SignUpModal = ({
  open,
  handleClose,
  setIsEmailConfirmedModal,
  heading,
}) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [fadeOut, setFadeOut] = useState(false);
  const [step, setStep] = useState(1);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);

  const modalRef = useRef(null);

  const closeModal = () => {
    setFadeOut(true);
    setTimeout(() => {
      handleClose();
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.classList.remove("Prop_detail_infoModal_fadeOut");
    }
  }, [open]);

  const emailSchema = Yup.object({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handlePhoneInputChange = (value) => {
    formik.setFieldValue("cno", value);
  };

  const formInitialValues = {
    fname: "",
    lname: "",
    password: "",
    email: "",
    cno: "",
    cpassword: "",
    selectedCountry: "CA",
    termsAndConditions: true,
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema:
      step === 1 ? emailSchema : isEmailExist ? LoginSchema : UserSignUpSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };
      if (step === 1) {
        //run Api to check Email
        const data = JSON.stringify({
          Email: values.email,
          PhoneNumber: "",
        });
        try {
          const response = await axios.post(
            `${baseURL}/api/DuplicateEmailPhoneNumber`,
            data,
            {
              headers,
            }
          );
          if (response?.data?.result === 1) {
            setIsLoading(false);
            setIsSubmitted(false);
            setStep(2);
          } else {
            setStep(2);
            setIsEmailExist(true);
            setIsLoading(false);
            setIsSubmitted(false);
          }
        } catch (error) {
          toast.error(error.message);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      } else if (step === 2) {
        isEmailExist
          ? handleLogin(headers, resetForm)
          : handleSignUp(headers, resetForm);
      }
    },
  });

  const handleLogin = async (headers, resetForm) => {
    const data = JSON.stringify({
      Email: formik.values.email,
      Password: formik.values.password,
    });
    try {
      const response = await axios.post(`${baseURL}/api/Userlogin`, data, {
        headers,
      });
      if (response?.data?.result === 1) {
        toast.success("Login Successful");
        dispatch(addProfileData(response.data));
        const jwt_token = response.data.data.refreshToken;
        const exp_time = response.data.data.refreshTokenExpiryTime;
        localStorage.setItem("trackVariable", "0");
        localStorage.setItem("anonymous", 1);
        localStorage.setItem("fullName", response?.data?.data?.firstName);
        localStorage.setItem("lastName", response?.data?.data?.lastName);
        localStorage.setItem("email", response?.data?.data?.email);
        localStorage.setItem("phoneNumber", response?.data?.data?.phoneNumber);
        localStorage.setItem("UserId", response?.data?.data?.id);
        dispatch(addToken(exp_time));
        // const emailConfirmed = response?.data?.data?.emailConfirmed;
        const refreshTokenExpiry = new Date(exp_time);
        cookies.set("jwt_authorization", jwt_token, {
          expires: refreshTokenExpiry,
          path: "/",
        });
        dispatch(addItem(1));
        resetForm();
        closeModal();
        window.location.reload();
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleSignUp = async (headers, resetForm) => {
    const data = JSON.stringify({
      Email: formik.values.email,
      Password: formik.values.password,
      FirstName: formik.values.fname,
      LastName: "",
      Phone: formik.values.cno,
      Type: "Buyer",
    });
    try {
      const response = await axios.post(`${baseURL}/api/UserRegister`, data, {
        headers,
      });
      if (response?.data?.result === 1) {
        dispatch(addProfileData(response.data));
        const jwt_token = response.data.data.refreshToken;
        const exp_time = response.data.data.refreshTokenExpiryTime;
        localStorage.setItem("trackVariable", "0");
        localStorage.setItem("anonymous", 1);
        localStorage.setItem("fullName", response?.data?.data?.firstName);
        localStorage.setItem("lastName", response?.data?.data?.lastName);
        localStorage.setItem("email", response?.data?.data?.email);
        localStorage.setItem("phoneNumber", response?.data?.data?.phoneNumber);
        localStorage.setItem("UserId", response?.data?.data?.id);
        dispatch(addToken(exp_time));
        const refreshTokenExpiry = new Date(exp_time);
        cookies.set("jwt_authorization", jwt_token, {
          expires: refreshTokenExpiry,
          path: "/",
        });
        dispatch(addItem(1));
        resetForm();
        closeModal();
        setIsEmailConfirmedModal(true);
      } else {
        toast.error(response.data.error[0]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={`Prop_detail_infoModal mobile_signUpModal SignUp_modal ${
          fadeOut ? "Prop_detail_infoModal_fadeOut" : ""
        }`}
        ref={modalRef}
      >
        <div className="row m-0 w-100 d-flex align-items-center Mobile_signUpGap">
          <div className="col-md-6 col-12 mbl_Xpadding0">
            <div className="d-flex align-itmes-start justify-content-between">
              <h3 className="LoginModal_heading">
                {step === 1
                  ? heading
                  : isEmailExist
                  ? "Access your account"
                  : "You are nearly complete!"}
              </h3>
              <div
                className="ps-2 Property_detailsWeb_hide"
                onClick={closeModal}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="SignUp_modalInputContainer">
              {step === 1 && (
                <div>
                  <FormLabel>Email address</FormLabel>
                  <br />
                  <FormControl className="w-100">
                    <input
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      name="email"
                      placeholder="Email address"
                      type="text"
                      className="request_Info_input"
                    />
                    {isSubmitted ? (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {formik.errors.email}
                      </span>
                    ) : null}
                  </FormControl>
                </div>
              )}

              {step === 2 && (
                <>
                  {!isEmailExist && (
                    <>
                      <div>
                        <FormLabel> Enter Name</FormLabel>
                        <br />
                        <FormControl className="w-100">
                          <input
                            value={formik.values.fname}
                            onChange={formik.handleChange}
                            name="fname"
                            placeholder="Your Name"
                            type="text"
                            className="request_Info_input"
                          />
                          {isSubmitted ? (
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.fname}
                            </span>
                          ) : null}
                        </FormControl>
                      </div>

                      <div style={{ marginTop: "12px" }}>
                        <FormLabel>Enter phone number</FormLabel>
                        <br />
                        <FormControl className="w-100">
                          <PhoneInput
                            size="small"
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="CA"
                            value={formik.values.cno}
                            onChange={handlePhoneInputChange}
                            name="phoneNumber"
                            id="phoneNumber"
                            className="Phone_input signUp_numberInput"
                            placeholder="Phone Number"
                          />
                          {isSubmitted ? (
                            <span style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.cno}
                            </span>
                          ) : null}
                        </FormControl>
                      </div>
                    </>
                  )}

                  <div style={{ marginTop: "12px" }}>
                    <FormLabel>Password</FormLabel>
                    <div className="request_Info_input request_pass_input d-flex align-items-center">
                      <FormControl className="w-100 d-flex align-items-center flex-row">
                        <input
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          name="password"
                          placeholder="Your password"
                          type={isPassword ? "password" : "text"}
                          style={{
                            border: "none",
                            outline: "none",
                            width: "calc(100% - 24px)",
                          }}
                        />
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsPassword(!isPassword)}
                        >
                          {isPassword ? (
                            <PasswordTypeEyeIcon />
                          ) : (
                            <PasswordShowEyeIcon />
                          )}
                        </span>
                      </FormControl>
                    </div>
                    {isSubmitted ? (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {formik.errors.password}
                      </span>
                    ) : null}
                  </div>

                  {!isEmailExist && (
                    <div style={{ marginTop: "12px" }}>
                      <FormLabel>Confirm Password</FormLabel>
                      <div className="request_pass_input request_Info_input d-flex align-items-center">
                        <FormControl className="w-100 d-flex align-items-center flex-row">
                          <input
                            value={formik.values.cpassword}
                            onChange={formik.handleChange}
                            name="cpassword"
                            placeholder="Confirm password"
                            type={isConfirmPassword ? "password" : "text"}
                            style={{
                              border: "none",
                              outline: "none",
                              width: "calc(100% - 24px)",
                            }}
                          />
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setIsConfirmPassword(!isConfirmPassword)
                            }
                          >
                            {isConfirmPassword ? (
                              <PasswordTypeEyeIcon />
                            ) : (
                              <PasswordShowEyeIcon />
                            )}
                          </span>
                        </FormControl>
                      </div>
                      {isSubmitted ? (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          {formik.errors.cpassword}
                        </span>
                      ) : null}
                    </div>
                  )}
                </>
              )}

              {step === 2 && isEmailExist && (
                <div className="text-center mt-3">
                  <NavLink to="/reset-password">
                    <span
                      style={{
                        fontSize: "15px",
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "var(--Accent-Dark-Gray, #4A4A4A)",
                      }}
                    >
                      Forgot Password?
                    </span>
                  </NavLink>
                </div>
              )}

              {isLoading ? (
                <div className="aligncenter justifycenter mt-3">
                  <RotatingLines width={50} strokeColor="grey" />
                </div>
              ) : (
                <div>
                  <button
                    type="submit"
                    onClick={() => {
                      formik.handleSubmit();
                      setIsSubmitted(true);
                    }}
                    style={{ marginTop: "16px" }}
                  >
                    {step === 1
                      ? "Next"
                      : isEmailExist
                      ? "Login"
                      : "Create an account"}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 col-12 SignUp_modalInformation">
            <div className="d-flex align-items-start gap-2">
              <div className="propertyDetail_homeValue_icon">
                <ExploreSoldListingIcon />
              </div>
              <div>
                <h6>Explore Sold</h6>
                <p>Explore years of sold & rented listings</p>
              </div>
            </div>
            <div className="d-flex align-items-start gap-2 mt-5">
              <div className="propertyDetail_homeValue_icon">
                <CompareFavouritesIcon />
              </div>
              <div>
                <h6>Compare your favourites</h6>
                <p>Compare listing prices, size, monthly cost and more</p>
              </div>
            </div>
            <div className="d-flex align-items-start gap-2 mt-5">
              <div className="propertyDetail_homeValue_icon">
                <NotificationIcon />
              </div>
              <div>
                <h6>Get listing alart</h6>
                <p>
                  Never miss another listing - sign up to be the first to know!
                </p>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SignUpModal;
