import React, { useState ,memo} from "react";
import { PropertyData } from "../PropertData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DetailsViewsIcon,
  HomeDesignIcon,
  FloorIcon,
  InteriorSpaceIcon,
  DetailsParkingIcon,
  HomeSecurityIcon,
  LaundaryIcon,
  BedOutlineIcon,
  OutDoorFeatureIcon,
  SchoolsIcon,
  DetailsKitchenIcon,
  DetailsUtilitiesIcon,
  AdditionalFeaturesIcon,
  LotDetailIcon,
  CommunityDetailIcon,
  FinancialDetailIcon,
  PropertyDetailsIcon,
} from "../../../SvgIcons/Icons";

const BasicDetails = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };
  return (
    <div className="w-100">
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion PropertyDetails_MobileAccordion DetailsAccordion_withborder py-2"
      >
        <AccordionSummary
          expandIcon={
            isMobileView &&
            (expanded ? (
              <RemoveIcon className="Property_minusIcon" />
            ) : (
              <AddIcon className="Property_plusIcon" />
            ))
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <PropertyDetailsIcon />}
            <h2 className="property_Details_heading">
              Grand Central Mimico Details
            </h2>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <p className="PropertyDetails_Buy_reason mt-1">
            Micro details of building for all floorplans.
          </p>
          <div className="Basic_detailsCategoryContainer mt-3">
            {PropertyData?.HomeDetails?.Views && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <DetailsViewsIcon />
                    <h6>Views</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.Views?.map((item, index) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.HomeDesign && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <HomeDesignIcon />
                    <h6>HomeDesign</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.HomeDesign?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.interiorSpace && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <InteriorSpaceIcon />
                    <h6>Interior Spaces</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.interiorSpace?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.flooring && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <FloorIcon />
                    <h6>Flooring</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.flooring?.map((item, index) => {
                      return <li key={index}>{item}</li>;
                    })}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.bedAndBathDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <BedOutlineIcon color={"#7e7e7e"} size={"18"} />
                    <h6>Beds and Baths</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.bedAndBathDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.securityDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <HomeSecurityIcon />
                    <h6>Home Security</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.securityDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.laundryDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <LaundaryIcon />
                    <h6>Laundry</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.laundryDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.parkingDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <DetailsParkingIcon color={"#7e7e7e"} size={"18"} />
                    <h6>ParKing</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.parkingDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.outdoorFeatures && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <OutDoorFeatureIcon />
                    <h6>Outdoor Features</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.outdoorFeatures?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.kitchenDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <DetailsKitchenIcon />
                    <h6>Kitchen</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.kitchenDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.schoolDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <SchoolsIcon />
                    <h6>Schools</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.schoolDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.utilitiesDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <DetailsUtilitiesIcon />
                    <h6>Utilities</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.utilitiesDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.additionalFeatureDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <AdditionalFeaturesIcon />
                    <h6>Additional Features</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.additionalFeatureDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.lotWidthDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <LotDetailIcon />
                    <h6>Lot details</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.lotWidthDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.communityDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <CommunityDetailIcon />
                    <h6>Community Details</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.communityDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}

            {PropertyData?.HomeDetails?.financialDetails && (
              <div className="BasicDetails_subCategoryContainer">
                <div className="Basic_details_subCategory">
                  <div className="Basic_details_Category">
                    <FinancialDetailIcon />
                    <h6>Financial Details</h6>
                  </div>
                  <ul className="BasicDetails_List">
                    {PropertyData?.HomeDetails?.financialDetails?.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(BasicDetails);
