import React, { useRef } from "react";
import DashboardLayout from "../../Dashboard";
import "./profile.css";
import { Button, Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { BsUpload } from "react-icons/bs";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import axios from "axios";
import baseURL from "../../../config";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import {
  addUserProfileData,
  removeUserProfileData,
} from "../../../ReduxStore/Slices/UserProfileData";
import { Autocomplete, Box, TextField } from "@mui/material";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumber } from "react-phone-number-input";
import Geolocation from "../../../Components/GeoLocation/Geolocation";
import { useNavigate } from "react-router-dom";

const ProfileDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [SelectedImageURL, setSelectedImageURL] = useState(null);
  const [SelectedImage, setSelectedImage] = useState("");
  const [UserId, serUserId] = useState(null);
  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [emailFrequency, setEmailFrequency] = useState("");
  const [address, setAddress] = useState("");
  const jwtCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("jwt_authorization="));

  const cookies = new Cookies();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profileDataSlice[0]?.data);
  let isActive = true;
  let jwt_token;
  if (jwtCookie) {
    jwt_token = cookies.get("jwt_authorization");
    const decode = jwtDecode(jwt_token);
    const emailConfirmedStr =
      decode["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous"];
    const emailConfirmed = emailConfirmedStr === "True";
    if (emailConfirmed === false || profileData?.emailConfirmed === false)
      isActive = false;
  }

  const fetchapi = () => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    axios
      .get(`${baseURL}/api/User-Data/GetCurrentUser`, { headers })
      .then((response) => {
        localStorage.setItem("fullName", response?.data?.data?.firstName);
        localStorage.setItem("lastName", response?.data?.data?.lastName);
        localStorage.setItem("phoneNumber", response?.data?.data?.phoneNumber);
        setData(response?.data?.data);
        setEmailFrequency(response?.data?.data?.emailFrequency);
        setEmail(response?.data?.data?.email);
        setPhoneNumber(response?.data?.data?.phoneNumber);
        serUserId(response?.data?.data?.id);
        setfirstName(response?.data?.data.firstName);
        setlastName(response?.data?.data?.lastName);
        setAddress(response?.data?.data?.address || "");
        setloading(false);
        dispatch(removeUserProfileData());
        dispatch(addUserProfileData(response?.data?.data));
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  };

  useEffect(() => {
    // Fetch data only when the jwt authorization cookie exists
    if (jwtCookie) {
      fetchapi();
    } else {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwtCookie]);

  const handleSubmit = async () => {
    if (phoneNumber) {
      const selectedCountry = "CA";
      if (selectedCountry) {
        const isValid = isValidPhoneNumber(phoneNumber, selectedCountry);
        if (!isValid) {
          toast.error("Invalid phone number");
          return;
        }
      }
    } else if (!phoneNumber) {
      toast.error("Phone number is required");
      return;
    }

    setloading(true);
    const formData = new FormData();
    formData.append(
      "UpdateUserRequest",
      JSON.stringify({
        Id: UserId,
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phoneNumber,
        EmailFrequency: emailFrequency,
        Address: address,
        Apartment: "",
        City: "city",
        Postal: "",
        Province: "",
        Street: "",
        CivicNumber: "",
      })
    );

    if (SelectedImage) {
      formData.append("UserImage", SelectedImage);
    }

    try {
      const response = await axios.put(
        `${baseURL}/api/User-Data/UpdateUser`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (response.data.result === 1) {
        fetchapi();
        setloading(false);
        toast.success("Successfully Updated");
        setEditMode(false);
      }
      if (response.data.result === 0) {
        toast.error(response?.data?.error[0]);
        fetchapi();
        setEditMode(false);
      }
    } catch (error) {
      toast.error("There was a problem sending Data, Please Try Again!");
      setEditMode(false);
      setloading(false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e);
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setSelectedImageURL(URL.createObjectURL(selectedFile));
      setSelectedImage(selectedFile);
    }
  };

  const handleEmailFrequencyChange = (selectedFrequency) => {
    setEmailFrequency(selectedFrequency);
  };

  // ------------------------------------------------for handle auto complete address
  // For the Typing Input Change
  const [
    inputValue2ChangedByAutocomplete,
    setInputValue2ChangedByAutocomplete,
  ] = useState(false);
  const [newFilteredCities2, setNewFilteredCities2] = useState([]);
  const typingTimeout = useRef();

  const isOptionEqualToValue = (option, value) => {
    return option.code === value.code && option.label === value.label;
  };

  const handleInputChange2 = async (event, value) => {
    // setisAddressfilled(false);
    const inputValue2 = value;
    if (!inputValue2ChangedByAutocomplete) {
      setAddress(inputValue2);
    }

    if (inputValue2.trim() === "") {
      return;
    }

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }

    typingTimeout.current = setTimeout(async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt_token}`,
          },
        };
        const response = await axios.get(
          `https://config.assignmentsold.ca/api/User-Data/GetAddressResponse/${inputValue2}`,
          config
        );
        const filteredCities = response.data?.data.map((item) => {
          return {
            responseTitle: item.responseTitle,
            responseDesc: item.responseDesc,
          };
        });
        setNewFilteredCities2(filteredCities);
      } catch (error) {
        console.log("error", error);
      }
    }, 300);
  };

  const fetchUserLocation = async () => {
    try {
      const geo = await Geolocation();
      setAddress(geo?.address);
    } catch (error) {
      alert(
        "There is no location support on this device or it is disabled. To enable go to Settings > Privacy and security > Site Settings > Location"
      );
    }
  };

  return (
    <>
      <DashboardLayout>
        <div className="profileMargin__adujust">
          <div className="page-content heading mb-4">
            {loading === true ? (
              <center className="mt-5 text-center">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="70"
                  visible={loading}
                />
              </center>
            ) : (
              <>
                <div className="d-flex align-center sidemargin mobileviewcontainer ">
                  {/* <img src={`${baseURL}/${data?.imageUrl}`} className='profileimg mb-1 border rounded-circle' alt="" /> */}
                  <div className="AvatarFileUpload">
                    <div className="selected-image-holder">
                      <img
                        src={
                          SelectedImageURL ||
                          (data?.imageUrl
                            ? data?.imageUrl.startsWith(
                                "https://lh3.googleusercontent.com"
                              )
                              ? data?.imageUrl
                              : `${baseURL}/${data.imageUrl}`
                            : require("../../../images/ProfileAvatar.jpg"))
                        }
                        alt="User Profile"
                        loading="lazy"
                      />

                      <div className="image-overlay">
                        <label
                          htmlFor="avatarInput"
                          className="avatar-selector-btn"
                        >
                          <BsUpload className="upload-icon" />
                        </label>
                        <input
                          id="avatarInput"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="avatar-input"
                          disabled={!editMode}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="profiledesc mobileTopMargin ">
                    <h5 className="title ">Hi, {data?.firstName}</h5>
                    <p>{formatPhoneNumber(data?.phoneNumber)}</p>
                  </div>
                  {/* <div className='editprofile mobilevisibility'>
                                        {isActive ?
                                            editMode === false ?
                                                <button onClick={() => setEditMode(true)} className='text-primary btn' >  Edit Profile</button>
                                                : <button onClick={handleSubmit} className='text-primary btn border' >  Save Changes</button>
                                            :
                                            null
                                        }
                                    </div> */}
                </div>
                <div className="flex-column sidemargin pb-5">
                  <h5 className="mt-4 text-primary MobileSideMargin">
                    Basic Information
                  </h5>
                  <Form className="mt-3 ProfilefromContainer">
                    <Form.Group className="mb-3 profile_field">
                      <Form.Label className="bold-label">
                        Email Frequency
                      </Form.Label>
                      <div className="frequencyTap">
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          name="emailFrequency"
                          value="Never"
                          checked={emailFrequency === "Never"}
                          onChange={() => handleEmailFrequencyChange("Never")}
                          label="Never"
                          disabled={!editMode}
                        />
                        <Form.Check
                          type="radio"
                          aria-label="radio 1"
                          name="emailFrequency"
                          value="Daily"
                          checked={emailFrequency === "Daily"}
                          onChange={() => handleEmailFrequencyChange("Daily")}
                          label="Daily"
                          disabled={!editMode}
                        />
                        {/* <Form.Check type="radio" aria-label="radio 1"
                                                    name="emailFrequency"
                                                    value="Instant"
                                                    checked={emailFrequency === "Instant"}
                                                    onChange={() => handleEmailFrequencyChange("Instant")}
                                                    label="Instant"
                                                    disabled={!editMode}
                                                /> */}
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3 profile_field">
                      <Form.Label className="bold-label">Email</Form.Label>
                      <Form.Control
                        size="sm"
                        disabled={true}
                        type="email"
                        placeholder="Enter Your Email"
                        value={email} // Use the email state variable
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3  profile_field">
                      <Form.Label className="bold-label">First Name</Form.Label>
                      <Form.Control
                        size="sm"
                        disabled={!editMode}
                        type="text"
                        placeholder="Enter Your First Name"
                        value={firstName} // Use the email state variable
                        onChange={(e) => setfirstName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3  profile_field">
                      <Form.Label className="bold-label">Last Name</Form.Label>
                      <Form.Control
                        size="sm"
                        disabled={!editMode}
                        type="text"
                        placeholder="Enter Your Last Name"
                        value={lastName} // Use the email state variable
                        onChange={(e) => setlastName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3  profile_field">
                      <Form.Label className="bold-label">
                        Phone Number
                      </Form.Label>

                      <PhoneInput
                        size="small"
                        disabled={!editMode}
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry="CA"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        name="phoneNumber"
                        id="phoneNumber"
                        className="Phone_input"
                        placeholder="Enter Your Phone Number"
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3  mobileviewWidth">
                                            <Form.Label className='bold-label'>Address</Form.Label>
                                            <Form.Control
                                                size="sm" disabled={!editMode}
                                                type="text"
                                                placeholder="Enter Your Address"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </Form.Group> */}

                    {/* <Form.Group className="mb-3  mobileviewWidth">  
                                        <Dropdown className='fs-6' >
                                            <Dropdown.Toggle size='sm' 
                                                style={{
                                                    borderRadius: "5px",
                                                    borderColor: "transparent",
                                                    width: "95%"

                                                }}
                                            >
                                                {
                                                    data?.emailFrequency
                                                }
                                            </Dropdown.Toggle>
                                            {
                                                data?.emailFrequency && (
                                                    <Dropdown.Menu style={{ minWidth: '95%' }}>
                                                        {data?.emailFrequency !== 'Never' && (
                                                            <Dropdown.Item >Never</Dropdown.Item>
                                                        )}
                                                        {data?.emailFrequency !== 'Instant' && (
                                                            <Dropdown.Item >Instant</Dropdown.Item>
                                                        )}
                                                        {data?.emailFrequency !== 'Daily' && (
                                                            <Dropdown.Item >Daily</Dropdown.Item>
                                                        )}
                                                    </Dropdown.Menu>
                                                )
                                            }
                                        </Dropdown >
                                        </Form.Group> */}
                  </Form>

                  <h5 className="mt-5 text-primary MobileSideMargin">
                    Contact Information
                  </h5>
                  <Form className="ProfilefromContainer">
                    <Form.Group className="mb-3 profile_field">
                      <Form.Label className="bold-label">Address</Form.Label>
                      <Autocomplete
                        size="small"
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        freeSolo
                        options={newFilteredCities2}
                        inputValue={address}
                        onInputChange={(event, value) => {
                          handleInputChange2(event, value);
                          setInputValue2ChangedByAutocomplete(false);
                        }}
                        autoHighlight={false}
                        disabled={!editMode}
                        getOptionLabel={(option) => option.responseTitle}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                            key={option.responseDesc}
                          >
                            <div className="fd-col">
                              <div>
                                <FmdGoodOutlinedIcon className="locaicon" />
                                {option.responseTitle}
                              </div>
                              <div className="respDesc">
                                {option.responseDesc}
                              </div>
                            </div>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{ borderRadius: "4px" }}
                            size="small"
                            className="bg-white"
                            value={address}
                            id="outlined-basic"
                            label={"Enter your address"}
                            name="Location"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off",
                              style: { paddingRight: "9px" },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              sx: {
                                paddingRight: "9px !important",
                              },
                              endAdornment: (
                                <FmdGoodOutlinedIcon
                                  sx={{
                                    cursor: "pointer",
                                    color: "#f8a23e",
                                    ml: 1,
                                  }}
                                  onClick={fetchUserLocation}
                                />
                              ),
                            }}
                          />
                        )}
                        isOptionEqualToValue={isOptionEqualToValue}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setAddress(newValue.responseDesc);
                            setInputValue2ChangedByAutocomplete(true);
                          }
                        }}
                      />
                    </Form.Group>
                  </Form>

                  {editMode === false ? (
                    <Button
                      onClick={() => setEditMode(true)}
                      className="w-100  mt-3 profile_field MobileSideMargin"
                    >
                      {" "}
                      Edit Profile
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit}
                      className="w-100 mt-3 profile_field MobileSideMargin"
                    >
                      Save Changes
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </DashboardLayout>
      <ToastContainer />
    </>
  );
};

export default ProfileDashboard;
