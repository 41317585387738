import React, { useEffect, useState } from "react";
import baseURL from "../../../../config";
import { useLocation, useParams } from "react-router-dom";
import PropertyFaqs from "../../../../Components/PropertyDetails/SubComponents/PropertyFaqs";
import axios from "axios";

const ListingSeo = ({isMobileView}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams();
  const search = id;
  const searchType = params.get("searchtype");
  const [citySeoData, setCitySeoData] = useState([]);

  const fetchCityDescription = async (cityName) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/GetCityDetail?term=${cityName}`
      );
      if (response?.data?.result === 1) {
        setCitySeoData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (search && (!searchType || searchType === "Location"))
      fetchCityDescription(search);
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      {search &&
        (!searchType || searchType === "Location") &&
        citySeoData?.cityDescription?.length > 0 && (
          <div
          className={`"mt-5 ${isMobileView ? "px-3" : "pe-1"}`}
            dangerouslySetInnerHTML={{ __html: citySeoData?.cityDescription }}
          />
        )}

      {search && citySeoData?.cityFaqs?.length > 0 && (
        <div className="px-3 pt-5 listings_faqs">
          <PropertyFaqs data={citySeoData?.cityFaqs} isShowHeading={true} />
        </div>
      )}
    </>
  );
};

export default ListingSeo;
