import React, { useEffect, useState } from "react";
import HeaderSection from "../../HomePage/SubComponents/HeaderSection/HeaderSection";
import { ToastContainer } from "react-toastify";
import baseURL from "../../config";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet"; 

const SearchPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { id } = useParams();
  const searchType = params.get("searchtype");
  const [loading, setLoading] = useState(true);
  const [seoData, setSeoData] = useState({
    title: "AssignmentSold.ca",
    mainTitle: "AssignmentSold.ca",
    description:
      "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.",
    keywords: "assignment sold, assignment sale, condo, townhouse",
    imgUrl:
      "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png",
  });

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCityDescription = async (cityName) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/GetCityDetail?term=${cityName}`
      );
      if (response?.data?.result === 1) {
        const metaSeos = response?.data?.data?.citySeo[0]?.metaSeos || [];

        const mainTitleObject = metaSeos.find(
          (metaSeo) => metaSeo?.seoTag?.typevalue === "main_Title"
        );
        const titleObject = metaSeos.find(
          (metaSeo) => metaSeo?.seoTag?.typevalue === "title"
        );
        const descriptionObject = metaSeos.find(
          (metaSeo) => metaSeo?.seoTag?.typevalue === "description"
        );
        const keywordObject = metaSeos.find(
          (metaSeo) => metaSeo?.seoTag?.typevalue === "keywords"
        );

        setSeoData({
          mainTitle: mainTitleObject?.content || "AssignmentSold.ca",
          title: titleObject?.content || "AssignmentSold.ca",
          description:
            descriptionObject?.content ||
            "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached in Toronto, GTA, and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.",
          keywords:
            keywordObject?.content ||
            "assignment sold, assignment sale, condo, townhouse",
          imgUrl:
            "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id && (!searchType || searchType === "Location")) {
      fetchCityDescription(id);
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      {!loading && (
        <Helmet>
          <title>{seoData.title}</title>
          <link rel="canonical" href={`/${id}`} />
          <meta name="title" content={seoData.title} />
          <meta name="description" content={seoData.description} />
          <meta name="keywords" content={seoData.keywords} />

          {/* Open Graph meta tags */}
          <meta property="og:url" content={`/${id}`} />
          <meta property="og:title" content={seoData.title} />
          <meta property="og:description" content={seoData.description} />
          <meta property="og:image" content={seoData.imgUrl} />

          {/* Twitter meta tags */}
          <meta property="twitter:title" content={seoData.title} />
          <meta property="twitter:description" content={seoData.description} />
          <meta property="twitter:image" content={seoData.imgUrl} />
          <meta property="twitter:url" content={`/${id}`} />
        </Helmet>
      )}

      <div className={`${isMobileView ? "" : "p-3"}`}>
        <HeaderSection isSearchPage={true} />
      </div>

      <ToastContainer />
    </>
  );
};

export default SearchPage;
