import * as React from "react";
import "./MapandListing.css";
import { useSelector } from "react-redux";
import CardMain from "./CardMain";
import CardMainSkeleton from "./CardMainSkeleton";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FaSort } from "react-icons/fa";

export default function MapListingCards({
  isLoading,
  setDrawerLoading,
  handleHoveredListCard,
  totalRecordCount,
  sortingHeading,
  sortingOption,
  handleSortingChange,
}) {
  const filterDataSlice = useSelector((state) => state.filterDataSlice);
  const reduxStoreData = filterDataSlice?.flat();

  return (
    <>
      <div className="d-flex w-100 align-items-center justify-content-between px-2 gap-2 low-pr-m-v my-2">
        <p className="lprice font mb-0 d-flex flex-wrap align-items-center column-gap-2">
          {sortingHeading}
          {totalRecordCount !== undefined && (
            <span className="results textgreen mt-0 ms-0">{`(Results ${reduxStoreData?.length} of
            ${totalRecordCount})`}</span>
          )}
        </p>

        <div className="drop-down">
          <button
            className="btn btngreencolor sorting_blueBtn d-flex align-items-center"
            type="button"
            id="dropdownMenuButton2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="me-2">Sort</span><FaSort size={'18'}/>
          </button>

          <ul
            className="dropdown-menu menuwidth1 p-2"
            aria-labelledby="dropdownMenuButton1"
          >
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "PLH"}
                    onChange={() => handleSortingChange("PLH")}
                  />
                }
                label={<span className="font">Price (Low to High)</span>}
              />
            </li>
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "PHL"}
                    onChange={() => handleSortingChange("PHL")}
                  />
                }
                label={<span className="font">Price (High to Low)</span>}
              />
            </li>
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "PSLH"}
                    onChange={() => handleSortingChange("PSLH")}
                  />
                }
                label={
                  <span className="font">Price per sqft(Low to High)</span>
                }
              />
            </li>{" "}
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "PSHL"}
                    onChange={() => handleSortingChange("PSHL")}
                  />
                }
                label={
                  <span className="font">Price per sqft(High to Low)</span>
                }
              />
            </li>
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "SLH"}
                    onChange={() => handleSortingChange("SLH")}
                  />
                }
                label={<span className="font">Square Feet (Low to High)</span>}
              />
            </li>
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "SHL"}
                    onChange={() => handleSortingChange("SHL")}
                  />
                }
                label={<span className="font">Square Feet (High to Low)</span>}
              />
            </li>
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "CLF"}
                    onChange={() => handleSortingChange("CLF")}
                  />
                }
                label={<span className="font">Closest First</span>}
              />
            </li>
            <li className="">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sortingOption === "NE"}
                    onChange={() => handleSortingChange("NE")}
                  />
                }
                label={<span className="font">Newest First</span>}
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="mapListing_allcards">
        {isLoading
          ? Array.from(new Array(8))?.map((_, index) => (
              <CardMainSkeleton key={index} isMainCard={true} />
            ))
          : reduxStoreData?.map((item, index) => (
              <CardMain
                key={index}
                item={item}
                isLoading={isLoading}
                setDrawerLoading={setDrawerLoading}
                showOnhovered={true}
                cardPath={"assignment-sale/"}
                handleHoveredListCard={handleHoveredListCard}
              />
            ))}
      </div>
    </>
  );
}
